<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <!-- <a-form-model-item label="门名称" prop="doorName">
        <a-input v-model="form.doorName" placeholder="请输入门名称" />
      </a-form-model-item> -->
      <a-form-model-item label="门类型" prop="doorType">
        <a-radio-group v-model="form.doorType" button-style="solid">
          <a-radio-button :value="0">自动门</a-radio-button>
          <a-radio-button :value="1">工业门</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="设备编码" prop="devCode">
        <a-input v-model="form.devCode" placeholder="请输入设备编码" />
      </a-form-model-item>
      <a-form-model-item label="设备使用时间（开始）" prop="startTime">
        <a-input v-model="form.startTime" placeholder="请输入设备开始使用时间 例：830（早上八点半）" />
      </a-form-model-item>
      <a-form-model-item label="设备使用时间（结束）" prop="endTime">
        <a-input v-model="form.endTime" placeholder="请输入设备开始使用时间 例：1800（晚上六点）" />
      </a-form-model-item>
      <a-form-model-item label="开门信号输出时间" prop="one">
        <a-input v-model="form.one" placeholder="开门信号输出时间" />
      </a-form-model-item>
      <a-form-model-item label="停止信号输出时间" prop="two">
        <a-input v-model="form.two" placeholder="停止信号输出时间" />
      </a-form-model-item>
      <a-form-model-item label="关门信号输出时间" prop="three">
        <a-input v-model="form.three" placeholder="关门信号输出时间" />
      </a-form-model-item>
      <!-- <a-form-model-item label="是否在线 0:离线 1:在线" prop="online" >
        <a-input v-model="form.online" placeholder="请输入是否在线 0:离线 1:在线" />
      </a-form-model-item> -->
      <!-- <a-form-model-item label="是否在线" prop="online">
        <a-radio-group v-model="form.online" button-style="solid">
          <a-radio-button :value="0">离线</a-radio-button>
          <a-radio-button :value="1">在线</a-radio-button>
        </a-radio-group>
      </a-form-model-item> -->
      <!-- <a-form-model-item label="设备ICCID" prop="devIccId">
        <a-input v-model="form.devIccId" placeholder="请输入设备ICCID" />
      </a-form-model-item> -->

      <template>
        <span>用户手机号&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;已绑用户</span>
        <a-list item-layout="horizontal" :data-source="checkList">
          <a-list-item slot="renderItem" slot-scope="item, index">
            <span>{{ item.mobile }}&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{ item.nickname }}</span>
            <!-- 已绑用户：{{ item.nickname }}    用户手机号：{{ item.mobile }} -->
          </a-list-item>
        </a-list>

      </template>
      <div class="bottom-control">

        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="show">绑定用户</a-button>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <!-- 设备列表 -->
    <a-modal title=绑定用户 :visible="addressVisible" width="1300px" footer="" @cancel="addressVisible = false">
      <address-list ref="addressPage" @ok="changeAddress" />
    </a-modal>
  </a-drawer>
</template>

<script>
import { getDevice, addDevice, updateDevice } from '@/api/door/device'
import AddressList from './addressList'
export default {
  name: 'CreateForm',
  props: {

  },
  components: {
    AddressList
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      addressVisible: false,
      checkList: [],
      ids: [],
      // 表单参数
      form: {
        id: null,
        userIds:[],
        checkList: [],
        doorName: null,
        userId: [],
        doorType: 0,

        devCode: null,

        onlineTime: null,

        offTime: null,

        online: 0,
        startTime:null,
        endTime:null,

        devIccId: null,

        createTime: null,
        one:5,
        two:5,
        three:5

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        // doorName: [
        //   { required: true, message: '门名称不能为空', trigger: 'blur' }
        // ],
        doorType: [
          { required: true, message: '门类型不能为空', trigger: 'change' }
        ],
        devCode: [
          { required: true, message: '设备编码不能为空', trigger: 'blur' }
        ]
        ,
        startTime: [
          { required: true, message: '开始时间不能为空', trigger: 'blur' }
        ]
        ,
        endTime: [
          { required: true, message: '结束时间不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    changeAddress(list,userIds) {
      // console.log("传过来的" + list)
      this.checkList = list
      // this.userId = list.map(item => item.id)
      this.userId=userIds
      this.addressVisible = false;
      console.log("传过来的" + this.userId)
    },
    show() {
      this.addressVisible = true;
      this.$nextTick(() => {
        // this.form.userId=this.form.userResultList.map(e=> {return e.id});
        this.$refs.addressPage.init(this.form.userResultList.map(e => { return e.id }))
      })
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.checkList = []
      this.formType = 1
      this.form = {
        id: null,
        doorName: null,
        doorType: 0,
        devCode: null,
        onlineTime: null,
        offTime: null,
        online: 0,
        devIccId: null,
        createTime: null,
        one:5,
        two:5,
        three:5
      }
      this.$nextTick(() => {
        // this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.formType = 1
      this.reset()
      console.log("aaa" + this.form)
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDevice({ "id": id }).then(response => {
        this.form = response.data
        if(!response.data.userResultList){
          response.data.userResultList=[]
        }
        this.checkList = response.data.userResultList
        this.userId= response.data.userResultList.map(item => item.id)
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.form.userId = this.userId
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDevice(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDevice(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
